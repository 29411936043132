import { SvgIcon } from "@mui/material";
import React from "react";

const IconSuccess = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon
      style={props.style}
      viewBox="0 0 150 150"
      className={" circular-chart"}
    >
      <path
        d="M131.25 75C131.25 43.934 106.066 18.75 75 18.75C43.934 18.75 18.75 43.934 18.75 75C18.75 106.066 43.934 131.25 75 131.25C106.066 131.25 131.25 106.066 131.25 75Z"
        stroke="#154898"
        strokeWidth="12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100 62.5L68.75 93.75L50 75"
        stroke="#154898"
        strokeWidth="12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default IconSuccess;
