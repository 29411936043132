import { Grid } from "@mui/material";
import React from "react";

const LearnComponent = () => {
  return (
    <Grid container spacing={0} direction="column">
      <Grid item mobile={12} laptop={12} className="page-header">
        Learn
      </Grid>
      <Grid item mobile={12} laptop={12} className="page-header-desc">
        Coming soon...
      </Grid>
    </Grid>
  );
};

export default LearnComponent;
