import moment from "moment";

// date formatting logic
export const getPrettyDate = (inputDate, format, local = false) => {
  if (!inputDate || !format) return "Error";
  let formattedDate;
  try {
    // TODO implementation per time zone
    const dateObj = new Date(Number(inputDate)).toISOString();
    if (local) {
      // current timezone
      formattedDate = moment.utc(dateObj).local().format(format); // local will convert to the Users system time.
    } else {
      formattedDate = moment.utc(dateObj).format(format); // local will convert to the Users system time.
    }
  } catch (error) {
    console.log("Error in Utility functtion: ", error);
    return "Error";
  }
  return formattedDate;
};

export const convertUTCToDate = (timeStamp: Date) => {
  let formattedDate = "";
  const date = new Date(Number(timeStamp));
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    formattedDate = "Today";
  } else if (date.toDateString() === yesterday.toDateString()) {
    formattedDate = "Yesterday";
  } else {
    formattedDate = getPrettyDate(timeStamp, "dddd, MMM D, YYYY", true);
  }
  return formattedDate;
};

export const generateID = (blockType, module, modifier) => {
  return `${blockType}__${module}__${modifier}`;
};
