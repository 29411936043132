import gql from "graphql-tag";

export const Q_DEVICE_STATUS = gql`
  mutation GetDeviceStatus($deviceId: String!) {
    getDeviceStatus(deviceId: $deviceId) {
      patientPreferredName
      deviceType
      lastTransmissionTimestamp
      isDataTransmissionCritical
      dataTransmissionMessage
      batteryLevelPercentage
      isBatteryLevelCritical
      batteryLevelMessage
    }
  }
`;

export const Q_DEVICE_FROM_USER = gql`
  query getDeviceIdFromUser($email: String!) {
    patient(email: $email) {
      devices {
        deviceId
      }
    }
  }
`;
